<script lang="ts">
	import { apiFetch, type apiResponse } from '$lib/utils/fetch';
	import { useQueryClient, createQuery } from '@tanstack/svelte-query';
	import { openModal, closeAllModals, closeModal, modals } from 'svelte-modals';
	import Modal from '$lib/components/Modal.svelte';
	import Renew from '$lib/components/auth/Renew.svelte';
	import { authenticated, user } from '$lib/stores/user';
	import { derived } from 'svelte/store';
	import ActivateAccountReminder from './ActivateAccountReminder.svelte';
	import { page } from '$app/stores';
	import { toast } from 'svelte-sonner';
	import { permissions, type Permissions } from '$lib/stores/permissions';

	interface statusCheckResponse extends apiResponse {
		data: {
			member_current_year: boolean;
			message?: string;
		};
	}

	interface permissionsResponse extends apiResponse {
		data: Permissions;
	}

	const client = useQueryClient();

	const statusCheck = createQuery<{ statuscheck: statusCheckResponse }>({
		queryKey: ['statuscheck'],
		enabled: $authenticated,
		refetchInterval: 30000,
		queryFn: async () =>
			await apiFetch('/auth/renew_status', {
				method: 'GET'
			})
				.then((fetchData) => {
					return { statuscheck: fetchData };
				})
				.catch((error) => {
					return { statuscheck: error };
				})
	});

	const permissionsQuery = createQuery<{ permissionsResponse: permissionsResponse }>({
		queryKey: ['permissions'],
		enabled: $authenticated,
		queryFn: async () =>
			await apiFetch('/auth/permissions', {
				method: 'GET'
			})
				.then((fetchData) => {
					return { permissionsResponse: fetchData };
				})
				.catch((error) => {
					return { permissionsResponse: error };
				})
	});

	const permissionsData = derived(permissionsQuery, ($permissionsQuery) =>
		$permissionsQuery.data?.permissionsResponse.data && $permissionsQuery.isFetching === false
			? $permissionsQuery.data.permissionsResponse.data
			: null
	);

	$: switch ($permissionsData !== null) {
		case true:
			permissions.set($permissionsData);
			break;
		case false:
			break;
		case undefined:
			break;
	}

	$: switch ($authenticated) {
		case true:
			// console.log("Authenticated")
			client.invalidateQueries({ queryKey: ['statuscheck'] });
			client.invalidateQueries({ queryKey: ['permissions'] });
			client.fetchQuery({ queryKey: ['statuscheck'] });
			client.fetchQuery({ queryKey: ['permissions'] });
			break;
	}

	// Reactively check if the user needs to renew their membership

	const renewCheckData = derived(
		statusCheck,
		($renewCheck) =>
			!$renewCheck.data?.statuscheck.data.member_current_year &&
			$renewCheck.data?.statuscheck.data.member_current_year !== undefined &&
			$renewCheck.data?.statuscheck.data.member_current_year !== null &&
			$renewCheck.isFetching === false
	);

	// Check if the user needs to activate their account

	const activevateAccountCheck = derived(
		statusCheck,
		($renewCheck) =>
			$renewCheck.data?.statuscheck.data.message === 'Your account is not active' &&
			$renewCheck.isFetching === false
	);

	// Check if the user is not logged in

	const notLoggedInCheck = derived(
		statusCheck,
		($renewCheck) =>
			$renewCheck.data?.statuscheck.data.message === 'You are not logged in' &&
			$renewCheck.isFetching === false &&
			$authenticated === true
	);

	$: switch ($notLoggedInCheck) {
		case true:
			$user = null;
			toast.warning('Din session har gått ut');
			break;
	}

	$: switch ($renewCheckData) {
		case true:
			// console.log("Renew check is true")
			openModal(
				Modal,
				{ component: Renew, props: {} },
				{
					replace: true
				}
			);
			break;
		case false:
			// console.log("Renew check is false")
			break;
		case undefined:
			break;
	}

	$: switch ($activevateAccountCheck) {
		case true:
			if ($authenticated && $page.url.pathname.includes('activate')) {
				break;
			}
			openModal(
				Modal,
				{ component: ActivateAccountReminder, props: {} },
				{
					replace: true
				}
			);
			break;
		case false:
			break;
		case undefined:
			break;
	}
</script>
